import { useEffect, useReducer } from "react";
import { cookieReducer } from "../reducer/cookieReducer/cookieReducer";
import { showCookieSetting, acceptAllCookies, acceptCookies, consentCookies, checkCookieExist } from "../reducer/cookieReducer/actionTypes";
import { cookieNames, cookieNameObj } from "../constants/cookieList";


export function useCookie() {

    const initialState = {
        cookieNames: cookieNames,
        cookieConsent: cookieNameObj,
        visible: false,
        showCookieSettings: false
    }

    const [state, dispatch] = useReducer(cookieReducer, initialState)

    const onShowCookieSettings = () => dispatch({ type: showCookieSetting });

    const handleAcceptCookies = () => dispatch({ type: acceptCookies });

    const handleAcceptAllCookies = () => dispatch({ type: acceptAllCookies });

    const handleConsentCookie = cookies => e => dispatch({ type: consentCookies, payload: { cookies, event: e } })

    useEffect(() => { dispatch({ type: checkCookieExist }) }, [])

    return { state, onShowCookieSettings, handleAcceptCookies, handleAcceptAllCookies, handleConsentCookie };
}