import { showCookieSetting, acceptAllCookies, acceptCookies, consentCookies, checkCookieExist } from "./actionTypes";
import Cookies from "js-cookie";

const setCookie = (cookieName, cookieValue) => Cookies.set(cookieName, cookieValue, { expires: 365 });
const getCookie = (cookieName) => Cookies.get(cookieName);

export function cookieReducer(state, action) {
    switch (action.type) {
        case showCookieSetting:
            return { ...state, showCookieSettings: !state.showCookieSettings };
        case acceptCookies:
            for (const [key, value] of Object.entries(state.cookieConsent)) {
                setCookie(key, value);
            }
            return { ...state, visible: false };
        case acceptAllCookies:
            state.cookieNames.forEach((cookieName) => setCookie(cookieName, true))
            return { ...state, visible: false };
        case consentCookies:
            var updatedCookieConsent = state
            const { cookies, event } = action.payload;
            cookies.forEach(cookie => {
                updatedCookieConsent = { ...updatedCookieConsent, "cookieConsent": { ...updatedCookieConsent.cookieConsent, [cookie]: event.target.checked ? true : false } }
            });
            return updatedCookieConsent;
        case checkCookieExist:
            let cookiesExist = 0;
            state.cookieNames.forEach(cookieName => {
                if (getCookie(cookieName)) {
                    cookiesExist++;
                }
            });
            return { ...state, visible: !cookiesExist ? true : false }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}