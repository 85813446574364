import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./button.styles.scss";


const Button = ({
  color = "",
  border = "",
  disabled,
  status,
  onClick,
  children,
  arialLabel,
  style
}) => {


  return (
    <button
      style={style}
      aria-label={arialLabel}
      disabled={disabled}
      className={`button ${border} ${disabled ? " disabled" : ""} ${color}`.trim()}
      onClick={onClick}>
      {status !== "pending" ? children : <ClipLoader className="spinner" size={30} />}
    </button>
  );
};

export default Button;
