import React, { useRef } from "react";
import { useOpen } from "../../hooks/useOpen";
import { useShowHide } from "../../hooks/useShowHide";
import { checkChildrenComponents } from "../../utils/utils";
import "./accordion.styles.scss";

const Accordion = ({ color = "", style, indented = "", divider = "", children }) => {

    const contentRef = useRef(null);
    const { isOpen, handleIsOpen } = useOpen(false);
    useShowHide(isOpen, contentRef);

    checkChildrenComponents(children, 3)

    return (
        <div style={style} className={`accordion ${color}`.trim()}>
            <div className="accordion__header__container">
                <div className="accordion__header">{children[0]}</div>
                <div className={`fa-lg accordion__icon${isOpen ? " rotate" : ""}`} onClick={handleIsOpen}>
                    {children[1]}
                </div>
            </div>
            <div
                ref={contentRef}
                className={`accordion__content__container ${indented} ${divider}`.trim()}
            >
                {divider === "hr" && <hr />}
                {children[2]}
            </div>
        </div>
    )
}

export default Accordion;
