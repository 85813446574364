import GatsbyLink from "gatsby-link";
import React from "react";
import Button from "../button/Button";
import "./cookieSetting.styles.scss";

const CookieSetting = ({ cookieList, handleAcceptCookies, onShowCookieSettings, handleConsentCookie }) => {

    return (
        <>
            <h4>Cookie-Einstellungen</h4>
            <div className="cookie-setting__container">
                {cookieList.map((cookie, index) =>
                    <div key={index} className="cookie-setting__element">
                        <div className="cookie-setting__element__textbox">
                            <h5>{cookie.title}</h5>
                            <p>{cookie.text}</p>
                            <GatsbyLink className="link--underline" to={cookie.link}>mehr</GatsbyLink>
                        </div>
                        <div className="cookie-setting__element__select">
                            {!index ?
                                <input type="checkbox" checked readOnly /> :
                                <input type="checkbox" name={cookie.cookieType} onChange={handleConsentCookie(cookie.cookies, cookie.cookieType)} />
                            }
                        </div>
                    </div>
                )}
            </div>
            <div className="cookie-setting__btn--section">
                <Button outline negative onClick={onShowCookieSettings}>Zurück</Button>
                <Button onClick={handleAcceptCookies}>Speichern</Button>
            </div>
        </>
    )
}

export default CookieSetting
