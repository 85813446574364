import React from "react";
import { Link } from "gatsby";
import logo from "../../assets/images/logo.png";

import "./navbarLogo.styles.scss";

const NavbarLogo = ({ homeLink = "/", size = "", className = "", style = {} }) => {


    return (
        <div style={style} className={`nav__header ${size} ${className}`.trim()}>
            <Link to={homeLink}>
                <img src={logo} alt="Logo" />
            </Link>
        </div>
    )
}

export default NavbarLogo
