import React from "react";
import "./cookieModal.styles.scss";

const CookieModal = ({ isOpen, ...props }) => {

    return (
        <div className={`modal${isOpen ? " open" : ""}`} {...props} />

    )
}

export default CookieModal;
