import React from "react";
import { AiOutlineAlignRight, AiOutlineClose } from "react-icons/ai";
import "./toggleBtn.styles.scss";

const ToggleBtn = ({ isOpen, toggle, transparent = "" }) => {
  return (
    <>
      <button
        aria-label={isOpen ? "toggle-close-button" : "toggle-button"}
        type="button"
        className={`toggle-btn ${transparent} ${
          isOpen ? "close-button" : ""
        }`.trim()}
        onClick={toggle}
      >
        {isOpen ? <AiOutlineClose /> : <AiOutlineAlignRight />}
      </button>
    </>
  );
};

export default ToggleBtn;
