

export const { showCookieSetting, acceptAllCookies, acceptCookies, consentCookies, checkCookieExist } = {
    showCookieSetting: "SHOWCOOKIESETTING",
    acceptAllCookies: "ACCEPTALLCOOKIES",
    acceptCookies: "ACCEPTCOOKIES",
    consentCookies: "CONSENTCOOKIES",
    checkCookieExist: "CHECKCOOKIEEXIST"
}

