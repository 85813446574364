export const cookieTitle = "Nach den Cookies geht's weiter";

export const cookieOverviewText =
  "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.";

export const cookieList = [
  {
    title: "Essentielle Cookies",
    text: "Essentielle Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.",
    cookieType: "required",
    link: "/datenschutz",
    cookies: []
  },
  {
    title: "Statistiken",
    text: "Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.",
    cookieType: "performance",
    link: "/datenschutz",
    cookies: ["gatsby-gdpr-google-analytics", "gatsby-gdpr-google-tagmanager"]
  }
  // {
  //     title: "Marketing",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  //     cookieType: "marketing",
  //     link: "/datenschutzerklaerung/",
  //     cookies: ["gatsby-gdpr-facebook-pixel"]
  // }
];

export const cookieNames = cookieList.reduce(
  (prev, current) => [...prev, ...current.cookies],
  []
);

export const cookieNameObj = cookieNames.reduce((prev, current) => {
  return { ...prev, [current]: false };
}, {});
