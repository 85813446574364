import { FaInstagram } from 'react-icons/fa';
import { FiClock, FiMail } from 'react-icons/fi';
import { ImLocation, ImWhatsapp } from 'react-icons/im';

export const openingHours = {
  title: 'Öffnungszeiten',
  icon: FiClock,
  info: 'Dienstag - Samstag, 10 - 18 Uhr',
};

export const footerAddress = {
  title: 'Adresse',
  icon: ImLocation,
  info: 'Breite Str. 41, 47798 Krefeld',
};

export const socialMedia = {
  title: 'Kontakt',
  links: [
    {
      icon: FiMail,
      platform: 'email',
      info: 'E-Mail',
      url: 'mailto:modernart.tattoostudio@gmail.com',
    },
    {
      icon: FaInstagram,
      platform: 'instagram',
      info: 'Instagram',
      url: 'https://www.instagram.com/modernart.tattoo/',
    },
    // {
    //     icon: FaPhone,
    //     platform: "phone",
    //     info: "+49 6771 2659",
    //     url: "tel:+49 6771 2659"
    // },

    {
      icon: ImWhatsapp,
      platform: 'whatsapp',
      info: 'WhatsApp',
      url: 'https://wa.me/491632938899',
    },
  ],
};

export const footerInformationText = 'Modernart Tattoo';

export const footerLinks = [
  { label: 'Impressum', href: '/impressum' },
  { label: 'Datenschutz', href: '/datenschutz' },
];
