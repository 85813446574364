import React from "react";
import Footer from "../footer/Footer";
// import "./layout.styles.scss";
import Navbar from "../navbar/Navbar";
import CookieBanner from "../cookieBanner/CookieBanner";
import Seo from "../seo/Seo";
import { navbarLinks } from "../../constants/navbarData";

//TODO replace prop drilling for navbarlinks with useContext
const Layout = ({ children }) => {
  return (
    <>
    <Seo/>
      <Navbar
        navbarLinks={navbarLinks}
        sectionLink={false}
        direction="horizontal left"
      />
      <CookieBanner />
      {children}
      <Footer />
    </>
  );
};
export default Layout;
