import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

export function useScrolled(ref, initialState = false) {

    const [scrolled, setScrolled] = useState(initialState)

    const handleScrolled = () => {
        if (window.pageYOffset >= ref.current?.getBoundingClientRect().height) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", throttle(handleScrolled, 150));
        return () => {
            window.removeEventListener("scroll", handleScrolled);
        }
    }, [handleScrolled])

    return { scrolled };

}