export const navbarLinks = [
  {
    url: "/",
    link: "Startseite",
    external: false,
    subLinks: []
  },
  {
    url: "/aboutus",
    link: "Über uns",
    external: false,
    subLinks: []
  },
  {
    url: "/kontakt",
    link: "Kontakt",
    external: false,
    subLinks: []
  },
  {
    url: "/impressum",
    link: "Impressum",
    external: false,
    subLinks: []
  },
  {
    url: "/datenschutz",
    link: "Datenschutz",
    external: false,
    subLinks: []
  },
];
