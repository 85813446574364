import React from "react";
import Accordion from '../accordion/Accordion';
import { IoIosArrowDown } from "react-icons/io";
import "./sidebar.styles.scss";
import NavbarLink from "../navbarLink/NavbarLink";

const Sidebar = ({ isOpen, toggleSidebar, sidebarLinks, sectionLink, align = "" }) => {

    return (
        <div className={`sidebar${isOpen ? " open" : ""}`} >
            <div className={`sidebar__links__modal ${align}`.trim()}>
                {sidebarLinks?.map((link, index) => {
                    return (
                        link.subLinks ?
                            <Accordion
                                key={index}
                                indented="right"
                                noPadding
                            >
                                <div className={`sidebar__link__modal`}>
                                    <NavbarLink url={link.url} title={link.link} external={link.external} onClick={toggleSidebar} sectionLink={sectionLink} />
                                </div>
                                {!!link.subLinks.length && <IoIosArrowDown />}
                                {!!link.subLinks.length && <div className="sidebar__sublinks__modal">
                                    {link.subLinks.map((subLink, subIndex) =>
                                        <NavbarLink key={subIndex} className="sidebar__sublink__modal" url={subLink.url} title={subLink.link} external={link.external} onClick={toggleSidebar} sectionLink={sectionLink} />
                                    )}
                                </div>}
                            </Accordion> :
                            <div key={index} className={`sidebar__link__modal`}>
                                <NavbarLink url={link.url} title={link.link} external={link.external} onClick={toggleSidebar} sectionLink={sectionLink} />
                            </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Sidebar
