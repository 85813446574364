import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./navbarLink.styles.scss";

const NavbarLink = ({
  sectionLink,
  url,
  title,
  onClick,
  external,
  className = "navbar__link"
}) => {
  return (
    <>
      {sectionLink ? (
        //TODO correct anchorlink with right path
        // <AnchorLink
        //   to={`${sectionLink ? "/#" : ""}${url}`}
        //   onAnchorLinkClick={console.log("to", sectionLink, url)}
        //   className={`${className}`.trim()}
        // >
        //   {title}
        // </AnchorLink>
        <AnchorLink
          to={url}
          onAnchorLinkClick={onClick}
          className={`${className}`.trim()}
          stripHash
        >
          {title}
        </AnchorLink>
      ) : external ? (
        <a target="_blank" rel="noreferrer" href={url} className={`${className}`.trim()}>
          {title}
        </a>
      ) : (
        <Link to={url} onClick={onClick} className={`${className}`.trim()}>
          {title}
        </Link>
      )}
    </>
  );
};

export default NavbarLink;
