import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({
  lang,
  title,
  description,
  author,
  url,
  robots,
  ogTitle,
  ogDescription,
  ogImageUrl
}) => {
  const { site } = useStaticQuery(query);
  const { defaultTitle, defaultDescription, defaultUrl, defaultAuthor, og } =
    site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: url || defaultUrl,
    author: author || defaultAuthor,
    robots: robots || "index,follow",
    // OG
    ogTitle: ogTitle || og.defaultOgTitle,
    ogDescription: ogDescription || og.defaultOgDescription,
    ogImageUrl: ogImageUrl || og.defaultImageUrl,
    ogSiteName: og.defaultOgSiteName,
    ogUrl: og.defaultOgUrl,
    ogType: og.defaultOgType
  };
  return (
    <Helmet
      htmlAttributes={{
        lang: "de-DE"
      }}
      title={seo.title}
    >
      {seo.title && <meta name="title" content={seo.title} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.url && <meta property="url" content={seo.url} />}

      {/* OG */}
      {og && (
        <>
          <meta
            key="og:title"
            property="og:title"
            content={seo.ogTitle ?? seo.title}
          />
       
          <meta
            key="og:description"
            property="og:description"
            content={seo.ogDescription ?? seo.description}
          />
          <meta key="og:type" property="og:type" content={seo.ogType} />
          <meta
            key="og:site_name"
            property="og:site_name"
            content={seo.ogSiteName}
          />
          <meta key="og:url" property="og:url" content={seo.ogUrl} />
          <meta property="og:image" content={seo.ogImageUrl} />
        </>
      )}
      {seo.robots && (
        <meta name="robots" content={seo.robots ?? "index,follow"} />
      )}
      <meta
        key="googlebot"
        name="googlebot"
        content={seo.robots ?? "index,follow"}
      ></meta>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
      {/* <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/images/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      {cannonicalHRef && <link rel="canonical" href={cannonicalHRef} />} */}
    </Helmet>
  );
};

export default Seo;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultUrl: siteUrl
        defaultAuthor: author
        og {
          defaultOgDescription: description
          defaultOgSiteName: siteName
          defaultOgTitle: title
          defaultOgType: type
          defaultOgUrl: url
          defaultImageUrl: image
        }
      }
    }
  }
`;
