import React, { useRef } from "react";
import "./navbar.styles.scss";
// import NavbarLinks from "../navbarLinks/NavbarLinks";
import NavbarLogo from "../navbarLogo/NavbarLogo";
import Sidebar from "../sidebar/Sidebar";
import ToggleBtn from "../toggleBtn/ToggleBtn";
import { useScrolled } from "../../hooks/useScrolled";
import { useOpen } from "../../hooks/useOpen";

const NavbarBody = ({ direction = "", align = "", ...props }) => {
  return (
    <div className={`nav__body ${direction} ${align}`.trim()} {...props} />
  );
};

const NavbarHeaderContainer = ({ size, position, ...props }) => {
  return (
    <div className={`nav__header__container ${position}`.trim()} {...props} />
  );
};

// const NavbarLinksContainer = ({ ...props }) => {
//   return <div className={`nav__links__container`} {...props} />;
// };

const NavbarButtonContainer = ({ onScroll = "", ...props }) => {
  return <div className={`nav__btn ${onScroll}`} {...props} />;
};

const Navbar = ({
  navbarLinks,
  sectionLink,
  isSticky = true,
  color = "",
  sidebar = true,
  onScroll = ""
}) => {
  const navbarRef = useRef();

  // const { scrolled } = isSticky ? useScrolled(navbarRef, false) : {scrolled: undefined};
  const scrolled = useScrolled(navbarRef, false);

  const { isOpen, handleIsOpen } = useOpen(false);

  return (
    <nav
      ref={navbarRef}
      className={`${color} ${scrolled ? " sticky" : ""}`.trim()}
    >
      <NavbarBody direction="horizontal-reverse" align="space">
        <NavbarHeaderContainer position="bottom">
          <NavbarLogo
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "3rem",
              height: "3rem"
            }}
            size="small"
          />
        </NavbarHeaderContainer>

        {/* <NavbarLinksContainer>
          <NavbarLinks
            navbarLinks={navbarLinks}
            sectionLink={sectionLink}
            align="center"
          />
        </NavbarLinksContainer> */}
        {sidebar && (
          <>
            <NavbarButtonContainer>
              <ToggleBtn isOpen={isOpen} toggle={handleIsOpen} />
            </NavbarButtonContainer>
            <Sidebar
              isOpen={isOpen}
              toggleSidebar={handleIsOpen}
              sidebarLinks={navbarLinks}
              sectionLink={sectionLink}
              align="center"
            />
          </>
        )}
      </NavbarBody>
    </nav>
  );
};

export default Navbar;
