import React from "react";
import Button from "../button/Button";
// import CookieConsent from "react-cookie-consent";
import Modal from "../cookieModal/cookieModal";
import "./cookieBanner.styles.scss";
import CookieSetting from "../cookieSetting/CookieSetting";
import { cookieList, cookieOverviewText, cookieTitle } from "../../constants/cookieList";
import { useCookie } from "../../hooks/useCookie";
import { Link } from "gatsby";

const CookieBanner = () => {

  const { state: cookieState, onShowCookieSettings, handleAcceptCookies, handleAcceptAllCookies, handleConsentCookie } = useCookie();

  return (
    <>
      {cookieState.visible &&
        <>
          <Modal isOpen={cookieState.visible}>
            <div className="cookie-banner">
              <div className="cookie-banner__container">
                {!cookieState.showCookieSettings ?
                  <>
                    <h4>{cookieTitle}</h4>
                    <p className="cookie-banner__text">{cookieOverviewText}</p>
                    <div className="cookie-banner__btn--section">
                      <div className="cookie-banner__btn--secondary">
                        <Link to="/impressum">Impressum</Link>
                        <Link to="/datenschutz">Datenschutzerklärung</Link>
                      </div>
                      <div className="cookie-banner__btn--primary">
                        <Button outline negative onClick={onShowCookieSettings}>Cookie-Einstellungen</Button>
                        <Button onClick={handleAcceptAllCookies}>Akzeptieren</Button>
                      </div>
                    </div>
                  </> :
                  <CookieSetting
                    cookieList={cookieList}
                    onShowCookieSettings={onShowCookieSettings}
                    handleAcceptCookies={handleAcceptCookies}
                    handleConsentCookie={handleConsentCookie} />
                }
              </div>
            </div>
          </Modal>
        </>}
    </>
  )
  // return (
  //   <CookieConsent
  //     enableDeclineButton
  //     location="bottom"
  //     buttonText="Akzeptieren"
  //     cookieName="gdpr-popup"
  //     de
  //     style={{ background: "#2B373B" }}
  //     buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  //     expires={150}
  //     acceptOnScroll
  //   >
  //     Diese Website verwendet Cookies. Cookies helfen uns bei der Bereitstellung unserer Inhalte und Dienste. Durch die weitere Nutzung der Website stimmst Du der Verwendung zu.
  //     <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
  //   </CookieConsent>
  // );
};

export default CookieBanner;
