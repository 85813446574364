import React from "react";
import { Link } from "gatsby";
import "./footer.styles.scss";

import SocialMediaLink from "../socialMediaLink/SocialMediaLink";

import {
  socialMedia,
  footerInformationText,
  footerLinks,
  footerAddress,
  openingHours
} from "../../constants/footerData";

const Footer = (align = "") => {
  return (
    <footer id="footer">
      <div className={`footer__body ${align}`.trim()}>
        <div className="footer__box">
          <span className="footer__header">{openingHours.title}</span>
          <span className="footer__box--description">
            <openingHours.icon size={23} /> {openingHours.info}
          </span>
        </div>

        <span className="footer__divider" />
        <div className="footer__socialmedia">
          <span className="footer__header">{socialMedia.title}</span>

          <SocialMediaLink
            hoverCircle
            hoverGrow
            socialMediaLinks={socialMedia.links}
            info={true}
          />
        </div>
        <span className="footer__divider" />

        <div className="footer__box">
          <span className="footer__header">{footerAddress.title}</span>
          <span className="footer__box--description">
            <footerAddress.icon size={23} />
            {footerAddress.info}
          </span>
        </div>
      </div>

      <div className="footer__links">
        {footerLinks?.map((l) => {
          return (
            <Link to={l.href} key={l.href}>
              {l.label}
            </Link>
          );
        })}
      </div>
      <div className="footer__information">
        <span className="footer__creators">
          @{footerInformationText} {new Date().getFullYear()}
          <span className="footer__creators"></span> developed with ❤️ by{" "}
          <a target="_blank" rel="noreferrer" href="https://www.web-dna.de/">
            web DnA
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
